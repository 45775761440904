import React, { FC } from 'react';
import { DeepExtractType } from 'ts-deep-extract-types';

import { ParagraphLarge } from '../generic/Text';
import { Container, Section } from '../generic/View';
import Hero from '../Hero';
import * as Button from '../generic/Button';

type Props = DeepExtractType<
  GatsbyTypes.IndexPageTemplateQuery,
  ['markdownRemark', 'frontmatter', 'header_section']
>;

const HeaderSection: FC<Props> = ({
  heading,
  bg_image,
  catch_line,
  button
}) => {
  return (
    <Section className="pt-0 md:pt-0">
      <Hero title={heading || ''} imageSource={bg_image?.source || ''} />
      <Container className="md:flex md:flex-row-reverse md:items-center md:justify-between mt-ms md:mt-mm">
        <div
          className={`text-center md:text-left
                flex flex-col items-center md:block md:max-w-[43.125rem] mb-6 md:mb-0`}
        >
          <ParagraphLarge>{catch_line}</ParagraphLarge>
        </div>
        {button && button.text && (
          <div className="w-48 sm:w-55 md:w-full mx-auto md:mr-5 w">
            {button.type_of_button === 'primary' ? (
              <Button.Primary link="/partnership">
                {button?.text}
              </Button.Primary>
            ) : (
              <Button.Secondary link="/partnership">
                {button?.text}
              </Button.Secondary>
            )}
          </div>
        )}
      </Container>
    </Section>
  );
};

export default HeaderSection;
