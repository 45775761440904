import React, { FC } from 'react';
import { DeepExtractType } from 'ts-deep-extract-types';

import Content from '../RichContent';
import { Heading2 } from '../generic/Text';
import { Container, Section } from '../generic/View';

type Props = DeepExtractType<
  GatsbyTypes.IndexPageTemplateQuery,
  ['markdownRemark', 'frontmatter', 'mission_section']
>;

const MissionSection: FC<Props> = ({ heading, rich_text }) => {
  return (
    <Section>
      <Container>
        <Heading2 className="mb-mm md:mb-20 md:w-[44.5rem]">{heading}</Heading2>
        <div className="block lg:flex flex-row justify-end">
          <Content
            markdown={rich_text}
            className="w-full lg:w-198 paragraph-large"
          />
        </div>
      </Container>
    </Section>
  );
};

export default MissionSection;
