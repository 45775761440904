import React, { FC, useEffect, useState } from 'react';
import { DeepExtractType } from 'ts-deep-extract-types';
import emailValidator from 'email-validator';
import MailchimpSubscribe from 'react-mailchimp-subscribe';

import { ParagraphLarge } from '../generic/Text';
import { Container, Section } from '../generic/View';
import * as Button from '../generic/Button';
import { TextInput } from '../generic/Input';
import ErrorMessageSVG from '../../assets/svgs/error.svg';
import SendingStateSVG from '../../assets/svgs/sending.svg';
import SucessMessageSVG from '../../assets/svgs/success.svg';
import Content from '../RichContent';

type SubscriptionSectionProps = DeepExtractType<
  GatsbyTypes.IndexPageTemplateQuery,
  ['markdownRemark', 'frontmatter', 'subscription_section']
>;

interface Formdata {
  EMAIL: string;
}

const Message: FC<{ message: JSX.Element; svg: JSX.Element }> = ({
  message,
  svg
}) => {
  return (
    <div className="flex items-center">
      <div className="svg-wrapper h-5 md:h-8 mr-3 md:mr-5">{svg}</div>
      <div className="leading-5 md:leading-8 text-sm md:text-lg font-sans font-normal text-dark-gray">
        {message}
      </div>
    </div>
  );
};

interface Message {
  jsxParagraph: JSX.Element;
  type: 'sending' | 'error' | 'success';
}

const CustomForm: FC<{
  placeHolderText: string;
  buttonText: string;
  status: 'sending' | 'error' | 'success' | null;
  responseMessage: string | Error | null;
  onValidated: (formData: Formdata) => void;
  emailAddressPatternErrorMessageText: string;
  privacy_notice?: string;
}> = ({
  placeHolderText,
  responseMessage,
  buttonText,
  status,
  onValidated,
  emailAddressPatternErrorMessageText,
  privacy_notice
}) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState<Message | null>(null);

  useEffect(() => {
    switch (status) {
      case 'error':
        setMessage({
          jsxParagraph: (
            <p
              dangerouslySetInnerHTML={{
                __html: (responseMessage as string | Error).toString()
              }}
            />
          ),
          type: 'error'
        });
        break;
      case 'sending':
        setMessage({
          jsxParagraph: <p>sending...</p>,
          type: 'sending'
        });
        break;
      case 'success':
        setMessage({
          jsxParagraph: (
            <p
              dangerouslySetInnerHTML={{
                __html: (responseMessage as string | Error).toString()
              }}
            />
          ),
          type: 'success'
        });
        setEmail('');
        break;
      default:
        setMessage(null);
        break;
    }
  }, [status]);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!email || !emailValidator.validate(email)) {
      setMessage({
        jsxParagraph: <p>{emailAddressPatternErrorMessageText}</p>,
        type: 'error'
      });
      return;
    }

    onValidated({ EMAIL: email });
  };

  const messageSVGs = {
    error: <ErrorMessageSVG />,
    sending: <SendingStateSVG />,
    success: <SucessMessageSVG />
  };

  const showMessage = message ? (
    <div className="mt-5 md:mt-14 text-center">
      <Message message={message.jsxParagraph} svg={messageSVGs[message.type]} />
    </div>
  ) : null;

  return (
    <>
      <form onSubmit={handleSubmit} className="flex flex-col items-center">
        <TextInput
          onChange={handleOnChange}
          value={email}
          placeholder={placeHolderText}
          className="w-full sm:w-[21rem] md:w-140 mb-ms md:mb-6"
        />
        {privacy_notice && (
          <Content
            markdown={privacy_notice}
            className="w-full sm:w-[21rem] md:w-140 mb-ms md:mb-5"
          />
        )}
        <Button.Primary>{buttonText}</Button.Primary>
      </form>
      {showMessage}
    </>
  );
};

const MailchimpFormContainer: FC<{
  placeHolderText: string;
  buttonText: string;
  emailAddressPatternErrorMessageText: string;
  privacy_notice?: string;
}> = ({
  placeHolderText,
  buttonText,
  emailAddressPatternErrorMessageText,
  privacy_notice
}) => {
  const u = process.env.GATSBY_MAILCHIMP_U;
  const id = process.env.GATSBY_MAILCHIMP_AUDIENCE_ID;

  const postUrl = `${process.env.GATSBY_MAILCHIMP_POST_URL}?u=${u}&id=${id}`;

  return (
    <MailchimpSubscribe
      url={postUrl || ''}
      render={({ subscribe, status, message }) => (
        <CustomForm
          status={status}
          responseMessage={message}
          onValidated={(formData: Formdata) => subscribe(formData)}
          placeHolderText={placeHolderText}
          buttonText={buttonText}
          emailAddressPatternErrorMessageText={
            emailAddressPatternErrorMessageText
          }
          privacy_notice={privacy_notice}
        />
      )}
    />
  );
};

const SubscriptionSection: FC<SubscriptionSectionProps> = ({
  heading,
  email_placeholder,
  button,
  error_messages,
  privacy_notice
}) => {
  return (
    <Section>
      <Container className="flex flex-col items-center">
        <ParagraphLarge className="text-center mb-ms md:mb-mm md:w-[37.5rem]">
          {heading}
        </ParagraphLarge>
        <MailchimpFormContainer
          privacy_notice={privacy_notice}
          placeHolderText={email_placeholder || ''}
          buttonText={button?.text || ''}
          emailAddressPatternErrorMessageText={
            error_messages?.email_input_pattern || ''
          }
        />
      </Container>
    </Section>
  );
};

export default SubscriptionSection;
