import classNames from 'classnames';
import React, { FC } from 'react';
import { DeepExtractType } from 'ts-deep-extract-types';

import { Heading4, ParagraphLarge } from '../generic/Text';
import { Container, Section } from '../generic/View';

type UniqueBenefitsSectionProps = DeepExtractType<
  GatsbyTypes.IndexPageTemplateQuery,
  ['markdownRemark', 'frontmatter', 'unique_benefits_section']
>;

const HoverableCircle: FC<{
  title: string;
  className?: string;
}> = ({ title, className }) => {
  const circleStyles = `flex justify-center items-center border-2 
                          rounded-full text-center
                          h-[10.75rem] md:h-[21.5rem] 
                          w-[10.75rem] md:w-[22rem]`;
  const textStyles = 'w-[8.5rem] md:w-[17.5rem]';

  return (
    <div>
      <div
        className={classNames(
          `rounded-full`,
          className,
          `
                      mb-ms md:mb-10
                      flex flex-row md:block`
        )}
      >
        <div className={classNames(circleStyles, `border-dashed`)}>
          <ParagraphLarge className={textStyles}>{title}</ParagraphLarge>
        </div>
      </div>
    </div>
  );
};

const UniqueBenefitsSection: FC<UniqueBenefitsSectionProps> = ({
  heading,
  unique_benefits
}) => {
  let uniqueBenefitsLength = 0;
  if (unique_benefits) {
    uniqueBenefitsLength = unique_benefits.length;
  }
  return (
    <Section withGradient>
      <Container>
        <Heading4 borderBottom className="mb-ms md:mb-mm">
          {heading}
        </Heading4>
        <div
          className={classNames(
            `flex items-center 
                     flex-col sm:flex-row 
                     sm:flex-wrap 
                     sm:w-[22rem] md:w-[44.75rem] lg:w-full
                     sm:m-auto`,

            uniqueBenefitsLength % 2 === 0 &&
              `sm:pb-16 md:pb-[6.875rem] lg:pb-0`
          )}
        >
          {unique_benefits?.map((benefit, key) => {
            let className: string;

            if (key % 4 === 0) {
              className = 'lg:mr-[9.75rem]';
            } else if (key % 4 === 2) {
              className = 'lg:ml-[17.875rem] lg:mr-[9.75rem]';
            } else {
              className = `sm:relative 
                        sm:top-[5.5rem] md:top-[9.375rem] lg:top-0
                        sm:-left-[0.625rem] md:left-0`;
            }

            return (
              <HoverableCircle
                key={key}
                title={benefit?.text || ''}
                className={className}
              ></HoverableCircle>
            );
          })}
        </div>
      </Container>
    </Section>
  );
};

export default UniqueBenefitsSection;
