import React, { FC } from 'react';
import ReactPlayer from 'react-player/lazy';
import { DeepExtractType } from 'ts-deep-extract-types';

import Content from '../RichContent';
import { Heading4 } from '../generic/Text';
import { Container, Section } from '../generic/View';

type Props = DeepExtractType<
  GatsbyTypes.IndexPageTemplateQuery,
  ['markdownRemark', 'frontmatter', 'case_study_section']
>;

const CaseStudySection: FC<Props> = ({ heading, rich_text, video_href_to }) => {
  return (
    <Section>
      <Container>
        <Heading4 borderBottom className="mb-ms md:mb-mm">
          {heading}
        </Heading4>
        <div className="flex flex-col-reverse lg:flex-row md:justify-between">
          <Content markdown={rich_text} className="lg:w-140" />
          <div
            className={`mb-ms lg:mb-mm
                      w-full lg:w-[36.6875rem]
                      h-auto md:h-[15.625rem] lg:h-[22.5rem]`}
          >
            <ReactPlayer url={video_href_to} width="100%" height="100%" />
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default CaseStudySection;
