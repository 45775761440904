import classNames from 'classnames';
import React, { FC } from 'react';

export const TextInput: FC<{
  placeholder?: string;
  className?: string;
  [props: string]: any;
}> = ({ placeholder, className, ...props }) => {
  return (
    <input
      type="text"
      placeholder={placeholder}
      className={classNames(
        `font-sans font-normal rounded-lg border-2	
                border-dark-blue placeholder:text-light-grey`,
        className,
        `
                text-sm	md:text-lg
                leading-4 md:leading-5 
                h-10 md:h-[3.75rem] 
                py-3 md:py-5
                px-5`
      )}
      {...props}
    />
  );
};
