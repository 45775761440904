import React, { FC } from 'react';
import { DeepExtractType } from 'ts-deep-extract-types';

import Content from '../RichContent';
import { Heading3, Heading4 } from '../generic/Text';
import { Container, Section } from '../generic/View';

type Props = DeepExtractType<
  GatsbyTypes.IndexPageTemplateQuery,
  ['markdownRemark', 'frontmatter', 'platform_benefits_section']
>;

const PlatformBenefitsSection: FC<Props> = ({
  heading,
  platform_benefits,
  additional_benefits
}) => {
  return (
    <Section withGradient>
      <Container>
        <Heading4 borderBottom>{heading}</Heading4>
        <div className="flex flex-col lg:flex-row justify-between">
          <div className="lg:w-[32.25rem]">
            {platform_benefits?.map((benefit, index) => {
              return (
                <div key={index} className="mt-ms md:mt-mm">
                  <Heading3 className="mb-3 md:mb-ms">
                    {benefit?.heading}
                  </Heading3>
                  <Content markdown={benefit?.rich_text} />
                </div>
              );
            })}
          </div>
          <div
            className={`text-blue 
                            mt-ms md:mt-mm
                            lg:w-[26rem]`}
          >
            <Heading3 className="mb-3 md:mb-ms">
              {additional_benefits?.heading}
            </Heading3>
            <Content markdown={additional_benefits?.rich_text} />
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default PlatformBenefitsSection;
