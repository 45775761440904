import React, { FC } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

const commonStyles = `flex justify-center items-center font-medium
                      font-sans rounded-3xl transition-colors
                      text-[0.813rem] md:text-lg
                      leading-[0.938rem] md:leading-5
                      w-full sm:w-55 md:w-100 
                      h-7.5 md:h-10`;

export const Primary: FC<{ link?: string; className?: string }> = (props) => {
  const cn = classNames(
    commonStyles,
    props.className,
    `text-white bg-dark-blue hover:bg-green`
  );

  if (props?.link) {
    return (
      <Link to={props.link} className={cn}>
        {props.children}
      </Link>
    );
  } else {
    return <button className={cn}>{props.children}</button>;
  }
};

export const Secondary: FC<{ link?: string; className?: string }> = (props) => {
  const cn = classNames(
    commonStyles,
    props.className,
    `text-dark-blue border-2 border-dark-blue 
    hover:border-green hover:text-green`
  );

  if (props?.link) {
    return (
      <Link to={props.link} className={cn}>
        {props.children}
      </Link>
    );
  } else {
    return <button className={cn}>{props.children}</button>;
  }
};

export const ExternalLink: FC<{ link?: string; className?: string }> = (
  props
) => {
  return (
    <a
      href={props.link || '#'}
      rel="noreferrer"
      target="_blank"
      className={classNames(
        commonStyles,
        props.className,
        `text-dark-blue border-2 border-dark-blue 
      hover:border-green hover:text-green`
      )}
    >
      {props.children}
    </a>
  );
};
