import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { DeepExtractType } from 'ts-deep-extract-types';

import Layout from '../components/Layout';
import Footer from '../components/Footer';
import HeaderSection from '../components/indexSections/HeaderSection';
import SubscriptionSection from '../components/indexSections/SubscriptionSection';
import PlatformBenefitsSection from '../components/indexSections/PlatformBenefitsSection';
import MissionSection from '../components/indexSections/MissionSection';
import UniqueBenefitsSection from '../components/indexSections/UniqueBenefitsSection';
import CaseStudySection from '../components/indexSections/CaseStudySection';
import ChemicalLeasingSection from '../components/indexSections/ChemicalLeasingSection';

type Props = DeepExtractType<
  GatsbyTypes.IndexPageTemplateQuery,
  ['markdownRemark', 'frontmatter']
>;

export const IndexPageTemplate: FC<Props> = ({
  header_section,
  subscription_section,
  platform_benefits_section,
  mission_section,
  unique_benefits_section,
  case_study_section,
  chemical_leasing_section,
  footer_section
}) => {
  return (
    <>
      <HeaderSection
        heading={header_section?.heading}
        bg_image={header_section?.bg_image}
        catch_line={header_section?.catch_line}
        button={header_section?.button}
      />
      <hr className="w-12.5 h-1 text-green bg-green mt-7.5 mx-auto mb-7.5 md:hidden" />
      <SubscriptionSection
        heading={subscription_section?.heading}
        email_placeholder={subscription_section?.email_placeholder}
        privacy_notice={subscription_section?.privacy_notice}
        button={subscription_section?.button}
        error_messages={subscription_section?.error_messages}
      />
      <PlatformBenefitsSection
        heading={platform_benefits_section?.heading}
        platform_benefits={platform_benefits_section?.platform_benefits}
        additional_benefits={platform_benefits_section?.additional_benefits}
      />
      <MissionSection
        heading={mission_section?.heading}
        rich_text={mission_section?.rich_text}
      />
      <UniqueBenefitsSection
        heading={unique_benefits_section?.heading}
        unique_benefits={unique_benefits_section?.unique_benefits}
      />

      <CaseStudySection
        heading={case_study_section?.heading}
        rich_text={case_study_section?.rich_text}
        video_href_to={case_study_section?.video_href_to}
      />
      <ChemicalLeasingSection
        heading={chemical_leasing_section?.heading}
        rich_text={chemical_leasing_section?.rich_text}
        benefits={chemical_leasing_section?.benefits}
        button={chemical_leasing_section?.button}
        rich_text2={chemical_leasing_section?.rich_text2}
      />
      <Footer richText={footer_section?.rich_text || ''} />
    </>
  );
};

const IndexPage: FC<{ data: GatsbyTypes.IndexPageTemplateQuery }> = ({
  data
}) => (
  <Layout
    pageTitle={data.markdownRemark?.frontmatter?.pageTitle || ''}
    metaTagDescription={
      data.markdownRemark?.frontmatter?.meta_tag_description || ''
    }
  >
    <IndexPageTemplate
      pageTitle={data.markdownRemark?.frontmatter?.pageTitle}
      meta_tag_description={
        data.markdownRemark?.frontmatter?.meta_tag_description
      }
      header_section={data.markdownRemark?.frontmatter?.header_section}
      subscription_section={
        data.markdownRemark?.frontmatter?.subscription_section
      }
      platform_benefits_section={
        data.markdownRemark?.frontmatter?.platform_benefits_section
      }
      mission_section={data.markdownRemark?.frontmatter?.mission_section}
      unique_benefits_section={
        data.markdownRemark?.frontmatter?.unique_benefits_section
      }
      case_study_section={data.markdownRemark?.frontmatter?.case_study_section}
      chemical_leasing_section={
        data.markdownRemark?.frontmatter?.chemical_leasing_section
      }
      footer_section={data.markdownRemark?.frontmatter?.footer_section}
    />
  </Layout>
);

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        pageTitle
        meta_tag_description
        header_section {
          heading
          catch_line
          button {
            text
            type_of_button
          }
          bg_image {
            source
          }
        }
        subscription_section {
          heading
          email_placeholder
          privacy_notice
          button {
            text
          }
          error_messages {
            email_input_pattern
          }
        }
        platform_benefits_section {
          heading
          platform_benefits {
            heading
            rich_text
          }
          additional_benefits {
            heading
            rich_text
          }
        }
        mission_section {
          heading
          rich_text
        }
        unique_benefits_section {
          heading
          unique_benefits {
            text
          }
        }
        case_study_section {
          heading
          rich_text
          video_href_to
        }
        chemical_leasing_section {
          heading
          rich_text
          button {
            text
            href
          }
          benefits {
            text
          }
          rich_text2
        }
        footer_section {
          rich_text
        }
      }
    }
  }
`;
