import React, { FC } from 'react';
import { DeepExtractType } from 'ts-deep-extract-types';

import * as Button from '../generic/Button';
import Content from '../RichContent';
import { Heading4, ParagraphLarge } from '../generic/Text';
import { Container, Section } from '../generic/View';

type Props = DeepExtractType<
  GatsbyTypes.IndexPageTemplateQuery,
  ['markdownRemark', 'frontmatter', 'chemical_leasing_section']
>;

const ChemicalLeasingSection: FC<Props> = ({
  heading,
  rich_text,
  benefits,
  button,
  rich_text2
}) => {
  return (
    <Section withGradient>
      <Container>
        <Heading4 borderBottom className="mb-ms md:mb-mm">
          {heading}
        </Heading4>
        <Content
          markdown={rich_text}
          className="mb-ms md:mb-mm md:w-3/4 lg:w-198"
        />
        <div
          className={`flex flex-col relative
                    mb-ms md:mb-mm
                    lg:w-[68rem] lg:mx-auto`}
        >
          <div
            className={`absolute bg-center bg-no-repeat bg-contain
                      -z-[1] bg-[url('/assets/leasing_line.svg')]
                      top-2/4 -translate-y-2/4 left-2/4 
                      -translate-x-2/4 md:-translate-x-[calc(50%-82px)]
                      w-[8.625rem] md:w-[17rem] 
                      h-[11.625rem] md:h-[23.125rem]`}
          ></div>
          <div className={`flex flex-row justify-end`}>
            <ParagraphLarge
              className={`text-center cursor-pointer hover:text-dark-blue
                        w-55 md:w-125
                        mb-10 md:mb-[10.875rem]`}
            >
              {benefits ? benefits[0]?.text : ''}
            </ParagraphLarge>
          </div>
          <div className={`flex flex-row justify-start`}>
            <ParagraphLarge
              className={`text-center cursor-pointer hover:text-dark-blue
                        w-55 md:w-125
                        mb-8 md:mb-[10.875rem]`}
            >
              {benefits ? benefits[1]?.text : ''}
            </ParagraphLarge>
          </div>
          <div className={`flex flex-row justify-end`}>
            <ParagraphLarge
              className={`text-center cursor-pointer hover:text-dark-blue
                        w-55 md:w-125
                        lg:relative lg:right-25`}
            >
              {benefits ? benefits[2]?.text : ''}
            </ParagraphLarge>
          </div>
        </div>
        <Content
          markdown={rich_text2}
          className="mb-ms md:mb-mm md:w-3/4 lg:w-198"
        />
        <Button.ExternalLink link={button?.href} className="m-auto">
          {button?.text}
        </Button.ExternalLink>
      </Container>
    </Section>
  );
};

export default ChemicalLeasingSection;
